'use client';

import React, { useEffect, useState } from 'react';
import { Button } from '@/components/ui/button';
import { AlertCircle, Bug, ChevronRight, Copy, DatabaseBackup, Info, Network } from 'lucide-react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useDebugStore } from '@/stores/useDebugStore';
import { APICallsView } from './APICallsView';
import { StatsView } from './StatsView';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { formatDistanceToNow } from 'date-fns';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle, DrawerClose } from '@/components/ui/drawer';
import { useToast } from '@/components/ui/use-toast';

const MAX_LOGS = 50;

interface DebugLog {
  timestamp: string;
  type: 'api' | 'error' | 'info';
  message: string;
  data?: any;
  source?: string;
  level?: 'debug' | 'info' | 'warn' | 'error';
}

interface DebugMenuProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

const DebugMenu = ({ open, onOpenChange }: DebugMenuProps) => {
  console.log(
    'DebugMenu rendering, NODE_ENV:',
    process.env.NODE_ENV,
    'NEXT_PUBLIC_NODE_ENV:',
    process.env.NEXT_PUBLIC_NODE_ENV,
  );

  const [logs, setLogs] = useState<DebugLog[]>([]);
  const [localData, setLocalData] = useState<{
    clients?: any[];
    packages?: any[];
    sessions?: any[];
    messages?: any[];
  }>({});
  const { addApiCall } = useDebugStore();

  useEffect(() => {
    const storedLogs = localStorage.getItem('debug_logs');
    if (storedLogs) {
      setLogs(JSON.parse(storedLogs));
    }

    // Load local data
    const clients = localStorage.getItem('clients');
    const packages = localStorage.getItem('packages');
    const sessions = localStorage.getItem('sessions');
    const messages = localStorage.getItem('messages');

    setLocalData({
      clients: clients ? JSON.parse(clients) : [],
      packages: packages ? JSON.parse(packages) : [],
      sessions: sessions ? JSON.parse(sessions) : [],
      messages: messages ? JSON.parse(messages) : [],
    });

    // Set up API request interceptor
    const originalFetch = window.fetch;
    window.fetch = async (...args) => {
      const [url, config] = args;
      const startTime = Date.now();

      try {
        const response = await originalFetch(...args);
        const endTime = Date.now();
        const duration = endTime - startTime;

        // Add API call to debug store
        addApiCall({
          route: typeof url === 'string' ? url : url.toString(),
          method: config?.method || 'GET',
          duration,
          status: response.status,
          timestamp: new Date().toISOString(),
        });

        return response;
      } catch (error) {
        const endTime = Date.now();
        addApiCall({
          route: typeof url === 'string' ? url : url.toString(),
          method: config?.method || 'GET',
          duration: endTime - startTime,
          status: 0,
          timestamp: new Date().toISOString(),
          error: error.message,
        });
        throw error;
      }
    };

    return () => {
      window.fetch = originalFetch;
    };
  }, [addApiCall]);

  const copyToClipboard = (content: string) => {
    navigator.clipboard.writeText(content);
  };
  const { toast } = useToast();

  return (
    <Drawer open={open} onOpenChange={onOpenChange}>
      <DrawerContent className='h-[85vh] bg-white'>
        <DrawerHeader className='border-b border-gray-200 bg-white px-6 py-4'>
          <div className='flex items-center gap-2'>
            <Bug className='h-5 w-5 text-blue-600' />
            <DrawerTitle className='text-lg font-semibold'>Debug Panel</DrawerTitle>
          </div>
          <DrawerClose className='absolute right-4 top-4' />
        </DrawerHeader>
        <div className='flex-1 overflow-y-auto bg-gray-50 p-6'>
          <Tabs defaultValue='logs' className='h-full'>
            <TabsList className='mb-4 bg-white'>
              <TabsTrigger value='logs' className='data-[state=active]:bg-blue-50'>
                <AlertCircle className='mr-2 h-4 w-4' />
                Logs
              </TabsTrigger>
              <TabsTrigger value='api' className='data-[state=active]:bg-blue-50'>
                <Network className='mr-2 h-4 w-4' />
                API Calls
              </TabsTrigger>
              <TabsTrigger value='stats' className='data-[state=active]:bg-blue-50'>
                <Info className='mr-2 h-4 w-4' />
                Stats
              </TabsTrigger>
              <TabsTrigger value='data' className='data-[state=active]:bg-blue-50'>
                <DatabaseBackup className='mr-2 h-4 w-4' />
                Local Data
              </TabsTrigger>
            </TabsList>

            <TabsContent value='logs' className='h-full'>
              <Card>
                <CardHeader className='pb-3'>
                  <CardTitle className='text-base'>Application Logs</CardTitle>
                </CardHeader>
                <CardContent>
                  <ScrollArea className='h-[500px]'>
                    {logs.length === 0 ? (
                      <div className='flex h-32 items-center justify-center text-gray-500'>No logs available</div>
                    ) : (
                      <Accordion type='single' collapsible>
                        {logs.map((log, index) => (
                          <AccordionItem key={index} value={`item-${index}`}>
                            <AccordionTrigger className='hover:bg-gray-50'>
                              <div className='flex items-center gap-3'>
                                {log.type === 'error' && <AlertCircle className='h-4 w-4 text-red-500' />}
                                {log.type === 'api' && <Network className='h-4 w-4 text-blue-500' />}
                                {log.type === 'info' && <Info className='h-4 w-4 text-gray-500' />}
                                <span className='text-sm text-gray-600'>
                                  {formatDistanceToNow(new Date(log.timestamp), { addSuffix: true })}
                                </span>
                                <span className={`text-sm ${log.type === 'error' ? 'text-red-600' : 'text-gray-900'}`}>
                                  {log.message}
                                </span>
                              </div>
                            </AccordionTrigger>
                            <AccordionContent>
                              <div className='space-y-2 p-4'>
                                <div className='flex items-center justify-between'>
                                  <span className='text-sm font-medium text-gray-500'>Details</span>
                                  <Button
                                    size='sm'
                                    variant='outline'
                                    className='h-8'
                                    onClick={() => {
                                      navigator.clipboard.writeText(JSON.stringify(log.data, null, 2));
                                      toast({
                                        title: 'Copied to clipboard',
                                        description: 'Log details have been copied to your clipboard',
                                      });
                                    }}
                                  >
                                    <Copy className='mr-2 h-4 w-4' />
                                    Copy
                                  </Button>
                                </div>
                                <pre className='mt-2 whitespace-pre-wrap rounded-lg bg-gray-100 p-4 text-sm'>
                                  {JSON.stringify(log.data, null, 2)}
                                </pre>
                              </div>
                            </AccordionContent>
                          </AccordionItem>
                        ))}
                      </Accordion>
                    )}
                  </ScrollArea>
                </CardContent>
              </Card>
            </TabsContent>

            <TabsContent value='api'>
              <APICallsView />
            </TabsContent>

            <TabsContent value='stats'>
              <StatsView />
            </TabsContent>

            <TabsContent value='data'>
              <Card>
                <CardHeader>
                  <CardTitle>Local Storage Data</CardTitle>
                </CardHeader>
                <CardContent>
                  <ScrollArea className='h-[500px]'>
                    <div className='space-y-6'>
                      {Object.entries(localData).map(([key, value]) => (
                        <div key={key}>
                          <h3 className='mb-2 text-sm font-medium text-gray-900'>{key}</h3>
                          <pre className='whitespace-pre-wrap rounded-lg bg-gray-100 p-4 text-sm'>
                            {JSON.stringify(value, null, 2)}
                          </pre>
                        </div>
                      ))}
                    </div>
                  </ScrollArea>
                </CardContent>
              </Card>
            </TabsContent>
          </Tabs>
        </div>
      </DrawerContent>
    </Drawer>
  );
};

export default DebugMenu;
