'use client';

import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Input } from '@/components/ui/input';
import { useDebugStore } from '@/stores/useDebugStore';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

export function APICallsView() {
  const { apiCalls } = useDebugStore();
  const [searchTerm, setSearchTerm] = useState('');

  // Group API calls by route
  const routeStats = apiCalls.reduce((acc, call) => {
    const route = call.route;
    if (!acc[route]) {
      acc[route] = {
        count: 0,
        avgResponseTime: 0,
        successCount: 0,
        failureCount: 0,
        calls: [],
      };
    }
    
    acc[route].count++;
    acc[route].avgResponseTime = 
      (acc[route].avgResponseTime * (acc[route].count - 1) + call.duration) / acc[route].count;
    if (call.status >= 200 && call.status < 300) {
      acc[route].successCount++;
    } else {
      acc[route].failureCount++;
    }
    acc[route].calls.push(call);
    
    return acc;
  }, {} as Record<string, {
    count: number;
    avgResponseTime: number;
    successCount: number;
    failureCount: number;
    calls: any[];
  }>);

  // Filter routes based on search term
  const filteredRoutes = Object.entries(routeStats).filter(([route]) =>
    route.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Prepare time series data for the chart
  const timeSeriesData = apiCalls
    .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())
    .slice(-50) // Only show last 50 calls to prevent chart overcrowding
    .map(call => ({
      timestamp: new Date(call.timestamp).toLocaleTimeString(),
      duration: call.duration,
      route: call.route,
    }));

  if (apiCalls.length === 0) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>API Calls</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex h-[200px] items-center justify-center text-muted-foreground">
            No API calls recorded yet
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <div className="space-y-4">
      <Card>
        <CardHeader>
          <CardTitle>Response Times</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={timeSeriesData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="timestamp"
                  tick={{ fontSize: 12 }}
                  interval="preserveStartEnd"
                />
                <YAxis 
                  label={{ 
                    value: 'Duration (ms)', 
                    angle: -90, 
                    position: 'insideLeft',
                    style: { textAnchor: 'middle' }
                  }}
                />
                <Tooltip 
                  contentStyle={{ background: 'var(--background)', border: '1px solid var(--border)' }}
                  labelStyle={{ color: 'var(--foreground)' }}
                />
                <Line 
                  type="monotone" 
                  dataKey="duration" 
                  stroke="var(--primary)" 
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Route Statistics</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="mb-4">
            <Input
              placeholder="Search routes..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Route</TableHead>
                <TableHead>Total Calls</TableHead>
                <TableHead>Avg Response Time</TableHead>
                <TableHead>Success Rate</TableHead>
                <TableHead>Last Called</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredRoutes.map(([route, stats]) => (
                <TableRow key={route}>
                  <TableCell className="font-medium">{route}</TableCell>
                  <TableCell>{stats.count}</TableCell>
                  <TableCell>{stats.avgResponseTime.toFixed(2)}ms</TableCell>
                  <TableCell>
                    {((stats.successCount / stats.count) * 100).toFixed(2)}%
                  </TableCell>
                  <TableCell>
                    {new Date(
                      Math.max(...stats.calls.map(call => new Date(call.timestamp).getTime()))
                    ).toLocaleString()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
}
