import React from 'react';
import { useDebugStore } from '@/stores/useDebugStore';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';

export function StatsView() {
  const { stats, detailedStats } = useDebugStore();

  return (
    <div className="space-y-4">
      <Card>
        <CardHeader>
          <CardTitle>Performance Metrics</CardTitle>
        </CardHeader>
        <CardContent>
          <Accordion type="single" collapsible>
            <AccordionItem value="response-times">
              <AccordionTrigger>Response Times</AccordionTrigger>
              <AccordionContent>
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <span>Fastest Response:</span>
                    <span>{detailedStats.fastestResponse}ms</span>
                  </div>
                  <div className="flex justify-between">
                    <span>Slowest Response:</span>
                    <span>{detailedStats.slowestResponse}ms</span>
                  </div>
                  <div className="flex justify-between">
                    <span>90th Percentile:</span>
                    <span>{detailedStats.percentile90}ms</span>
                  </div>
                </div>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem value="error-analysis">
              <AccordionTrigger>Error Analysis</AccordionTrigger>
              <AccordionContent>
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <span>4xx Errors:</span>
                    <span>{detailedStats.error4xx}</span>
                  </div>
                  <div className="flex justify-between">
                    <span>5xx Errors:</span>
                    <span>{detailedStats.error5xx}</span>
                  </div>
                  <div className="flex justify-between">
                    <span>Network Errors:</span>
                    <span>{detailedStats.networkErrors}</span>
                  </div>
                </div>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem value="cache-stats">
              <AccordionTrigger>Cache Statistics</AccordionTrigger>
              <AccordionContent>
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <span>Cache Hit Rate:</span>
                    <span>{detailedStats.cacheHitRate}%</span>
                  </div>
                  <div className="flex justify-between">
                    <span>Cache Size:</span>
                    <span>{detailedStats.cacheSize} entries</span>
                  </div>
                </div>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem value="memory-usage">
              <AccordionTrigger>Memory Usage</AccordionTrigger>
              <AccordionContent>
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <span>Store Size:</span>
                    <span>{detailedStats.storeSize} KB</span>
                  </div>
                  <div className="flex justify-between">
                    <span>Active Listeners:</span>
                    <span>{detailedStats.activeListeners}</span>
                  </div>
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </CardContent>
      </Card>
    </div>
  );
}
