'use client';

import React, { createContext, useContext, useCallback } from 'react';

interface DebugContextType {
  logApiCall: (url: string, method: string, data?: any, response?: any) => void;
  logError: (message: string, error?: any) => void;
  logInfo: (message: string, data?: any) => void;
  updateLocalData: (key: string, data: any) => void;
}

const DebugContext = createContext<DebugContextType | undefined>(undefined);

export const DebugProvider = ({ children }: { children: React.ReactNode }) => {
  const logApiCall = useCallback((url: string, method: string, data?: any, response?: any) => {
    if (process.env.NODE_ENV !== 'development') return;

    const debugLog = {
      type: 'api',
      message: `${method} ${url}`,
      data: { request: data, response },
      timestamp: new Date().toISOString(),
    };

    const logs = JSON.parse(localStorage.getItem('debug_logs') || '[]');
    localStorage.setItem('debug_logs', JSON.stringify([debugLog, ...logs].slice(0, 100)));
  }, []);

  const logError = useCallback((message: string, error?: any) => {
    if (process.env.NODE_ENV !== 'development') return;

    const debugLog = {
      type: 'error',
      message,
      data: error,
      timestamp: new Date().toISOString(),
    };

    const logs = JSON.parse(localStorage.getItem('debug_logs') || '[]');
    localStorage.setItem('debug_logs', JSON.stringify([debugLog, ...logs].slice(0, 100)));
  }, []);

  const logInfo = useCallback((message: string, data?: any) => {
    if (process.env.NODE_ENV !== 'development') return;

    const debugLog = {
      type: 'info',
      message,
      data,
      timestamp: new Date().toISOString(),
    };

    const logs = JSON.parse(localStorage.getItem('debug_logs') || '[]');
    localStorage.setItem('debug_logs', JSON.stringify([debugLog, ...logs].slice(0, 100)));
  }, []);

  const updateLocalData = useCallback((key: string, data: any) => {
    if (process.env.NODE_ENV !== 'development') return;
    localStorage.setItem(`debug_${key}`, JSON.stringify(data));
  }, []);

  return (
    <DebugContext.Provider value={{ logApiCall, logError, logInfo, updateLocalData }}>
      {children}
    </DebugContext.Provider>
  );
};

export const useDebug = () => {
  const context = useContext(DebugContext);
  if (context === undefined) {
    throw new Error('useDebug must be used within a DebugProvider');
  }
  return context;
};
