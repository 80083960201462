import { create } from 'zustand';

interface APICall {
  route: string;
  method: string;
  duration: number;
  status: number;
  timestamp: string;
  error?: string;
}

interface DetailedStats {
  fastestResponse: number;
  slowestResponse: number;
  percentile90: number;
  error4xx: number;
  error5xx: number;
  networkErrors: number;
  cacheHitRate: number;
  cacheSize: number;
  storeSize: number;
  activeListeners: number;
}

interface DebugState {
  apiCalls: APICall[];
  stats: {
    totalApiCalls: number;
    avgResponseTime: number;
    errorRate: number;
    activeSessions: number;
  };
  detailedStats: DetailedStats;
  addApiCall: (call: APICall) => void;
  clearStats: () => void;
  refreshStats: () => void;
}

export const useDebugStore = create<DebugState>((set, get) => ({
  apiCalls: [],
  stats: {
    totalApiCalls: 0,
    avgResponseTime: 0,
    errorRate: 0,
    activeSessions: 0,
  },
  detailedStats: {
    fastestResponse: 0,
    slowestResponse: 0,
    percentile90: 0,
    error4xx: 0,
    error5xx: 0,
    networkErrors: 0,
    cacheHitRate: 0,
    cacheSize: 0,
    storeSize: 0,
    activeListeners: 0,
  },
  addApiCall: (call: APICall) => {
    set((state) => {
      const newApiCalls = [...state.apiCalls, call];
      const totalCalls = newApiCalls.length;
      
      // Calculate average response time
      const avgResponseTime =
        newApiCalls.reduce((sum, c) => sum + c.duration, 0) / totalCalls;
      
      // Calculate error rate
      const errorCalls = newApiCalls.filter(
        (c) => c.status >= 400 || c.status < 200
      ).length;
      const errorRate = (errorCalls / totalCalls) * 100;

      // Update detailed stats
      const responseTimes = newApiCalls.map((c) => c.duration).sort((a, b) => a - b);
      const error4xx = newApiCalls.filter((c) => c.status >= 400 && c.status < 500).length;
      const error5xx = newApiCalls.filter((c) => c.status >= 500).length;
      const networkErrors = newApiCalls.filter((c) => c.status === 0).length;

      return {
        apiCalls: newApiCalls,
        stats: {
          totalApiCalls: totalCalls,
          avgResponseTime,
          errorRate,
          activeSessions: state.stats.activeSessions,
        },
        detailedStats: {
          ...state.detailedStats,
          fastestResponse: responseTimes[0],
          slowestResponse: responseTimes[responseTimes.length - 1],
          percentile90: responseTimes[Math.floor(responseTimes.length * 0.9)],
          error4xx,
          error5xx,
          networkErrors,
        },
      };
    });
  },
  clearStats: () => {
    set({
      apiCalls: [],
      stats: {
        totalApiCalls: 0,
        avgResponseTime: 0,
        errorRate: 0,
        activeSessions: 0,
      },
      detailedStats: {
        fastestResponse: 0,
        slowestResponse: 0,
        percentile90: 0,
        error4xx: 0,
        error5xx: 0,
        networkErrors: 0,
        cacheHitRate: 0,
        cacheSize: 0,
        storeSize: 0,
        activeListeners: 0,
      },
    });
  },
  refreshStats: () => {
    const state = get();
    // Recalculate all stats
    const totalCalls = state.apiCalls.length;
    if (totalCalls === 0) return;

    const avgResponseTime =
      state.apiCalls.reduce((sum, call) => sum + call.duration, 0) / totalCalls;
    
    const errorCalls = state.apiCalls.filter(
      (call) => call.status >= 400 || call.status < 200
    ).length;
    const errorRate = (errorCalls / totalCalls) * 100;

    const responseTimes = state.apiCalls
      .map((call) => call.duration)
      .sort((a, b) => a - b);
    
    set({
      stats: {
        ...state.stats,
        totalApiCalls: totalCalls,
        avgResponseTime,
        errorRate,
      },
      detailedStats: {
        ...state.detailedStats,
        fastestResponse: responseTimes[0],
        slowestResponse: responseTimes[responseTimes.length - 1],
        percentile90: responseTimes[Math.floor(responseTimes.length * 0.9)],
      },
    });
  },
}));
